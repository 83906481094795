<template>
    <div>
        <b-table
            :striped="striped"
            :bordered="bordered"
            :borderless="borderless"
            :outlined="outlined"
            :small="small"
            :hover="hover"
            :dark="dark"
            :fixed="fixed"
            :foot-clone="footClone"
            :no-border-collapse="noCollapse"
            :items="this.row.item.site.metal_rollout"
            :fields="fields"
            :head-variant="headVariant"
            :table-variant="tableVariant"
            :empty-text="this.$t('client.components.interventionMetalRolloutsDetails.table.labels.noData')"
            show-empty
        >
        </b-table>
        <div>
            <b-button pill size="sm" variant="primary" :href="row.item.bill_document.url" target="_blank" v-if="row.item.bill_document">
                {{ row.item.bill_document.filename }}
                <b-icon icon="cloud-download"></b-icon>
            </b-button>
            <b-button class="ml-2" pill size="sm" variant="primary" :href="row.item.bi_document.url" target="_blank" v-if="row.item.bi_document">
                {{ $t('client.components.interventionMetalRolloutsDetails.table.labels.downloadBiDocument') }}
                <b-icon icon="cloud-download"></b-icon>
            </b-button>
            <b-button
                    :href="document.url"
                    :key="document"
                    class="ml-2"
                    pill
                    size="sm"
                    target="_blank"
                    v-for="document in row.item.documents"
                    variant="primary"
            >
                {{ document.filename }}
                <b-icon icon="cloud-download"></b-icon>
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientInterventionMetalRolloutsDetails',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fields: [
                {
                    key: 'full_address',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.fullAddress')
                },
                {
                    key: 'size',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.size')
                },
                {
                    key: 'axe_access',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.axeAccess')
                },
                {
                    key: 'axe_type',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.axeType')
                },
                {
                    key: 'engine_access',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.engineAccess')
                },
                {
                    key: 'material',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.material')
                },
                {
                    key: 'avoid_fall',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.avoidFall')
                },
                {
                    key: 'unclutch',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.unclutch')
                }
            ],
            striped: false,
            bordered: true,
            borderless: false,
            outlined: false,
            small: true,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,
            headVariant: 'dark',
            tableVariant: '',
            noCollapse: false
        };
    },
    methods: {
        test() {
            console.log(this.row.item);
        }
    }
};
</script>
