<template>
    <div>
        <b-button
            class="d-inline"
            size="sm"
            variant="link"
            :title="$t('sst.components.interventionTableActions.tooltips.details')"
            v-b-modal="'modal-metal-rollout' + item.id"
        >
            <b-icon icon="pencil" variant="info"></b-icon>
        </b-button>
        <b-button
            size="sm"
            variant="link"
            :title="$t('sst.components.interventionTableActions.tooltips.details')"
            @click="deleteMetalRollout"
        >
            <b-icon icon="trash" variant="info"></b-icon>
        </b-button>
        <sst-metal-rollout-modal
            :item="item"
            :id="'modal-metal-rollout' + item.id"
            :title="$t('sst.pages.metalRollout.modal.edit.title')"
        ></sst-metal-rollout-modal>
    </div>
</template>

<script>
import SstMetalRolloutModal from '@/components/sst/metal-rollout/SstMetalRolloutModal';
import { SiteRepository } from '@/repositories';
// import eventBus from '@/plugins/eventBus';

export default {
    name: 'SstMetalRolloutTableActions',
    components: {
        SstMetalRolloutModal
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        deleteMetalRollout() {
            this.$bvModal
                .msgBoxConfirm(this.$t('sst.components.confirmDeleteModal.message'), {
                    title: this.$t('sst.components.confirmDeleteModal.title'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: this.$t('shared.general.buttons.validate'),
                    cancelVariant: 'danger',
                    cancelTitle: this.$t('shared.general.buttons.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value === true) {
                        SiteRepository.delete(this.item.id)
                            .then(() => {
                                this.$toast.success(this.$t('sst.components.metalRolloutModal.notifications.delete.success'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                                // eventBus.$emit('reloadSstMetalRolloutsTable');
                            })
                            .catch(error => {
                                console.log(error);
                                this.$toast.error(this.$t('sst.components.metalRolloutModal.notifications.delete.error'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                            });
                    }
                })
                .catch(err => {
                    // An error occurred
                    console.log(err);
                });
        }
    }
};
</script>

<style scoped></style>
