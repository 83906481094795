<template>
    <div>
        <!-- modal -->
        <b-modal
            :id="'validation-modal' + item.id"
            ref="modal"
            :title="$t('client.components.quotationTableAction.modalValidate.title')"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            :ok-title="$t('client.components.quotationTableAction.modalValidate.buttons.validate')"
            :cancel-title="$t('client.components.quotationTableAction.modalValidate.buttons.cancel')"
            :ok-variant="'danger'"
        >
            <p>
                <span class="font-weight-bold">
                    {{ $t('client.components.quotationTableAction.modalValidate.text') }}
                </span>
            </p>

            <validation-observer immediate ref="observer" v-slot="{ invalid, handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmitForm)">
                    <validation-provider name="memo" v-slot="validationContext">
                        <b-form-group>
                            <b-form-textarea
                                id="memo"
                                v-model="form.memo"
                                :state="getValidationState(validationContext)"
                                :placeholder="$t('client.components.quotationTableAction.modalValidate.form.memo.placeholder')"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>

                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- button -->
        <b-button
            v-if="!refreshData"
            size="sm"
            variant="link"
            v-b-modal="'validation-modal' + item.id"
            :title="$t('client.components.quotationTableAction.tooltips.validate')"
            @click.prevent=""
        >
            <b-iconstack font-scale="1" variant="success">
                <b-icon stacked icon="circle"></b-icon>
                <b-icon stacked icon="check"></b-icon>
            </b-iconstack>
        </b-button>

        <!-- loading indicator -->
        <div v-if="refreshData">
            <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
        </div>
    </div>
</template>

<script>
import { QuotationRepository } from '@/repositories';

export default {
    name: 'ClientQuotationApproveModal',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            refreshData: false,
            form: {
                memo: ''
            }
        };
    },
    methods: {
        resetModal() {
            this.form.memo = '';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.onSubmitForm();
        },
        async onSubmitForm() {
            // Exit when the form isn't valid
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                return;
            }
            this.refreshData = true;
            const { memo } = this.form;

            QuotationRepository.validateQuotation(this.item.id, memo)
                .then(() => {
                    this.refreshData = false;
                    this.$toast.success(
                        this.$t('client.components.quotationTableAction.notifications.success.validation', { id: this.item.id })
                    );
                    this.$nextTick(() => {
                        this.$bvModal.hide('validation-modal' + this.item.id);
                    });
                    this.$emit('removeCurrentElement');
                })
                .catch(() => {
                    this.refreshData = false;
                    this.$toast.error(this.$t('client.components.quotationTableAction.notifications.errors.validation'));
                    this.$nextTick(() => {
                        this.$bvModal.hide('validation-modal' + this.item.id);
                    });
                });
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        }
    }
};
</script>
