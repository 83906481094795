<template>
    <div>
        <b-button
            size="sm"
            variant="link"
            :title="$t('client.components.interventionTableActions.tooltips.details')"
            @click="row.toggleDetails"
        >
            <b-icon :icon="row.detailsShowing ? 'eye-slash' : 'eye'" font-scale="1.5" variant="info"></b-icon>
        </b-button>
    </div>
</template>

<script>
export default {
    name: 'ClientInterventionDetailsButton',
    props: {
        row: {
            type: Object,
            required: true
        }
    }
};
</script>
