<template>
    <div>
        <div>
            <b-form-checkbox switch :checked="item.quotation" @change="change" :key="item.id"></b-form-checkbox>
        </div>
    </div>
</template>

<script>
import { BafRepository } from '@/repositories';

export default {
    name: 'SstBafQuotationCheckboxAction',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        change(value) {
            BafRepository.updateInterventionQuotation(this.item.id, { quotation: value })
                .then(() => {
                    // this.$toast.success(this.$t('sst.components.bafQuotationCheckboxAction.notifications.' + value + '.success'));
                })
                .catch(() => {
                    // this.$toast.error(this.$t('sst.components.bafQuotationCheckboxAction.notifications.' + value + '.error'));
                });
        }
    }
};
</script>
