<template>
    <b-card>
        <spinner v-if="refreshData" />
        <div class="accordion" role="tablist" v-if="!refreshData">
            <client-intervention-document-card :data="documentsData" :purchaseId="row.item.id"></client-intervention-document-card>
            <client-intervention-comment-card :data="commentsData"></client-intervention-comment-card>
        </div>
    </b-card>
</template>

<script>
import { InterventionRepository } from '@/repositories';
import Spinner from '@/components/shared/element/Spinner';
import ClientInterventionDocumentCard from '@/components/client/intervention/ClientInterventionDocumentCard';
import ClientInterventionCommentCard from '@/components/client/intervention/ClientInterventionCommentCard';

export default {
    name: 'ClientInterventionTableDetails',
    components: {
        Spinner,
        ClientInterventionDocumentCard,
        ClientInterventionCommentCard
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            refreshData: true,
            documentsData: [],
            commentsData: []
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            InterventionRepository.getInterventionDocuments(this.row.item.id)
                .then(response => {
                    this.documentsData = response.data.data;
                    InterventionRepository.getInterventionComments(this.row.item.id)
                        .then(response => {
                            this.refreshData = false;
                            this.commentsData = response.data.data;
                        })
                        .catch(error => {
                            this.refreshData = false;
                            console.log(error);
                        });
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        }
    }
};
</script>

<style>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>
