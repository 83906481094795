<template>
    <b-dropdown
        id="dropdown-grouped"
        :text="dropdownGroupParams.title"
        :disabled="dropdownGroupParams.disabled"
        :right="dropdownGroupParams.right"
        :size="dropdownGroupParams.size"
        :variant="dropdownGroupParams.variant"
    >
        <b-dropdown-group
            id="dropdown-group-1"
            :header="optionGroup.title"
            v-for="(optionGroup, index) in dropdownGroupParams.groupOptions"
            :key="index"
        >
            <b-dropdown-item-button
                @click="click(option.id, optionGroup.id)"
                v-for="(option, key) in optionGroup.options"
                :key="key"
            >
                {{ option.name }}
            </b-dropdown-item-button>

            <b-dropdown-divider v-if="showDropdownDivider(index)"></b-dropdown-divider>
        </b-dropdown-group>
    </b-dropdown>
</template>

<script>
export default {
    name: 'DropdownGroup',
    props: {
        dropdownGroupParams: {
            type: Object,
            required: true,
            right: false,
            size: null,
            variant: null
        }
    },
    methods: {
        click(optionId, optionGroupId) {
            this.$emit('clickDropdownOption', { optionId, optionGroupId });
        },
        showDropdownDivider(optionGroupIndex) {
            return this.dropdownGroupParams.groupOptions.length > 1 && optionGroupIndex % 2 === 0;
        }
    }
};
</script>
