<template>
    <div>
        <!-- modal -->
        <b-modal
            :id="'cancel-modal' + item.id"
            ref="modal"
            :title="$t('client.components.quotationTableAction.modalRefuse.title')"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            :ok-title="$t('client.components.quotationTableAction.modalRefuse.buttons.validate')"
            :cancel-title="$t('client.components.quotationTableAction.modalRefuse.buttons.cancel')"
            :ok-variant="'danger'"
        >
            <p>
                <span class="font-weight-bold">
                    {{ $t('client.components.quotationTableAction.modalRefuse.labels.placeOfIntervention') }}
                </span>
                {{ item.site }}
            </p>

            <p>
                <span class="font-weight-bold">
                    {{ $t('client.components.quotationTableAction.modalRefuse.labels.description') }}
                </span>
                {{ item.description }}
            </p>

            <p>
                <span class="font-weight-bold">
                    {{ $t('client.components.quotationTableAction.modalRefuse.labels.htAmount') }}
                </span>
                {{ $n(item.ht_amount, 'currency') }}
            </p>

            <hr class="dotted" />

            <h4>{{ $t('client.components.quotationTableAction.modalRefuse.labels.rufusOfQuote') }}</h4>

            <validation-observer immediate ref="observer" v-slot="{ invalid, handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmitForm)">
                    <validation-provider
                        :name="$t('client.components.quotationTableAction.form.errors.reasonRequired')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group>
                            <b-form-select
                                id="reason"
                                name="reason"
                                v-model="form.reason"
                                :options="options"
                                :state="getValidationState(validationContext)"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider name="memo" v-slot="validationContext">
                        <b-form-group>
                            <b-form-textarea
                                id="memo"
                                v-model="form.memo"
                                :state="getValidationState(validationContext)"
                                :placeholder="$t('client.components.quotationTableAction.form.memo.placeholder')"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>

                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- button -->
        <b-button
            v-if="!refreshData"
            size="sm"
            variant="link"
            v-b-modal="'cancel-modal' + item.id"
            @click.prevent=""
            :title="$t('client.components.quotationTableAction.tooltips.cancel')"
        >
            <b-icon icon="x-circle" font-scale="1" variant="danger"></b-icon>
        </b-button>

        <!-- loading indicator -->
        <div v-if="refreshData">
            <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
        </div>
    </div>
</template>

<script>
import { QuotationRepository } from '@/repositories';

export default {
    name: 'ClientQuotationCancelModal',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            refreshData: false,
            options: [
                { value: null, text: this.$t('client.components.quotationTableAction.form.reason.selectOption') },
                { value: 1, text: this.$t('client.components.quotationTableAction.form.reason.quoteTooExpensive') },
                { value: 2, text: this.$t('client.components.quotationTableAction.form.reason.quoteNotDetailed') },
                { value: 3, text: this.$t('client.components.quotationTableAction.form.reason.receiptTime') },
                { value: 4, text: this.$t('client.components.quotationTableAction.form.reason.noDecision') },
                { value: 5, text: this.$t('client.components.quotationTableAction.form.reason.other') }
            ],
            form: {
                memo: '',
                reason: null
            }
        };
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        resetModal() {
            this.form.memo = '';
            this.form.reason = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.onSubmitForm();
        },
        async onSubmitForm() {
            // Exit when the form isn't valid
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                return;
            }
            this.refreshData = true;
            const { memo, reason } = this.form;

            // Hide the modal manually
            QuotationRepository.cancelQuotation(this.item.id, memo, reason)
                .then(() => {
                    this.refreshData = false;
                    this.$toast.error(this.$t('client.components.quotationTableAction.notifications.success.cancel', { id: this.item.id }));
                    this.$nextTick(() => {
                        this.$bvModal.hide('cancel-modal' + this.item.id);
                    });
                    this.$emit('removeCurrentElement');
                })
                .catch(() => {
                    this.refreshData = false;
                    this.$toast.error(this.$t('client.components.quotationTableAction.notifications.errors.validation'));
                    this.$nextTick(() => {
                        this.$bvModal.hide('cancel-modal' + this.item.id);
                    });
                });
        }
    }
};
</script>
