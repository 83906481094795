<template>
    <div>
        <div v-if="row.item.block_date !== null">
            <b-badge pill variant="danger">{{ $t('sst.pages.pendingInvoice.table.radioButtonFilter.blocked') }}</b-badge>
        </div>
        <div v-else-if="row.item.payment_validation_date !== null">
            <b-badge pill variant="success">{{ $t('sst.pages.pendingInvoice.table.radioButtonFilter.validated') }}</b-badge>
        </div>
        <div v-else>
            <b-badge pill variant="warning">{{ $t('sst.pages.pendingInvoice.table.radioButtonFilter.inProgress') }}</b-badge>
        </div>
    </div> </template
>arni

<script>
export default {
    name: 'SstPendingInvoiceDetailsButton',
    props: {
        row: {
            type: Object,
            required: true
        }
    }
};
</script>
