<template>
    <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.document variant="secondary" v-if="data.length > 0">
                {{ $t('client.components.interventionDocumentCard.document') }}
                <b-badge class="mr-2" variant="light" pill>{{ data.length }}</b-badge>
                <span class="when-opened">
                    <b-icon icon="arrows-collapse" aria-hidden="true"></b-icon>
                </span>
                <span class="when-closed">
                    <b-icon icon="arrows-expand" aria-hidden="true"></b-icon>
                </span>
            </b-button>
            <b-button block v-b-toggle.document variant="secondary" v-if="data.length === 0" disabled>
                {{ $t('client.components.interventionDocumentCard.notFoundDocuments') }}
            </b-button>
        </b-card-header>
        <b-collapse id="document" visible accordion="my-accordion" role="tabpanel" v-if="data.length > 0">
            <b-card-body>
                <b-card-text>
                    <b-list-group>
                        <b-list-group-item
                            class="d-flex justify-content-between align-items-center"
                            v-for="(document, label) in data"
                            :key="label"
                        >
                            {{ document.filename }}
                            <b-button pill size="sm" :href="document.downloadUrl" target="_blank">
                                {{ $t('client.components.interventionDocumentCard.buttons.download') }}
                                <b-icon icon="cloud-download"></b-icon>
                            </b-button>
                        </b-list-group-item>
                    </b-list-group>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    name: 'ClientInterventionDocumentCard',
    props: {
        data: {
            type: Array,
            required: true
        },
        purchaseId: {
            type: Number,
            required: true
        }
    },
    methods: {}
};
</script>
