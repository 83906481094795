<template>
    <div>
        <b-modal
            :id="id"
            size="lg"
            ref="modal"
            :title="title"
            @hidden="resetModal"
            @ok="handleOk"
            :ok-title="$t('client.components.quotationTableAction.modal.buttons.validate')"
            :cancel-title="$t('client.components.quotationTableAction.modal.buttons.cancel')"
            :ok-variant="'primary'"
            :cancel-variant="'danger'"
            :centered="true"
        >
            <validation-observer immediate ref="observer" v-slot="{ invalid, handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <validation-provider
                        :name="$t('sst.pages.metalRollout.table.columns.size')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.size')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-input
                                id="size"
                                name="size"
                                v-model="form.size"
                                :state="getValidationState(validationContext)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider :name="$t('sst.pages.metalRollout.table.columns.fullAddress')" v-slot="validationContext">
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.fullAddress')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-input
                                id="full_address"
                                name="full_address"
                                v-model="form.full_address"
                                :state="getValidationState(validationContext)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.pages.metalRollout.table.columns.engineAccess')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.engineAccess')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-select
                                id="engine_access"
                                name="engine_access"
                                v-model="form.engine_access"
                                :options="accessEnumOptions"
                                :state="getValidationState(validationContext)"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.pages.metalRollout.table.columns.axeAccess')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.axeAccess')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-select
                                id="axe_access"
                                name="axe_access"
                                v-model="form.axe_access"
                                :options="accessEnumOptions"
                                :state="getValidationState(validationContext)"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.pages.metalRollout.table.columns.axeType')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.axeType')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-select
                                id="axe_type"
                                name="axe_type"
                                v-model="form.axe_type"
                                :options="axeTypeEnumOptions"
                                :state="getValidationState(validationContext)"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.pages.metalRollout.table.columns.material')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.material')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-input
                                id="material"
                                name="material"
                                v-model="form.material"
                                :options="axeTypeEnumOptions"
                                :state="getValidationState(validationContext)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.pages.metalRollout.table.columns.avoidFall')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.avoidFall')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-select
                                id="avoid_fall"
                                name="avoid_fall"
                                v-model="form.avoid_fall"
                                :options="accessEnumOptions"
                                :state="getValidationState(validationContext)"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.pages.metalRollout.table.columns.unclutch')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.unclutch')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-select
                                id="unclutch"
                                name="unclutch"
                                v-model="form.unclutch"
                                :options="accessEnumOptions"
                                :state="getValidationState(validationContext)"
                            ></b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider :name="$t('sst.pages.metalRollout.table.columns.memo')" v-slot="validationContext">
                        <b-form-group :label="$t('sst.pages.metalRollout.table.columns.memo')" label-cols-sm="4" label-cols-lg="3">
                            <b-form-textarea
                                id="memo"
                                name="memo"
                                v-model="form.memo"
                                :state="getValidationState(validationContext)"
                            ></b-form-textarea>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-form>
            </validation-observer>
            <template #modal-footer>
                <b-button :variant="'secondary'" @click="handleOk">
                    {{ $t('client.components.quotationTableAction.modal.buttons.validate') }}
                </b-button>
                <b-button :variant="'danger'" @click="closeModal">
                    {{ $t('client.components.quotationTableAction.modal.buttons.cancel') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { SiteRepository } from '@/repositories';
import eventBus from '@/plugins/eventBus';

export default {
    name: 'SstMetalRolloutModal',
    props: {
        id: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: false
        },
        mainRow: {
            type: Object,
            required: true
        },
        site: {
            required: false
        },
        accessEnumOptions: {
            type: Array,
            required: true
        },
        axeTypeEnumOptions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            form: {
                engine_access: this.item ? this.item.engine_access : null,
                axe_access: this.item ? this.item.axe_access : null,
                axe_type: this.item ? this.item.axe_type : null,
                material: this.item ? this.item.material : '',
                avoid_fall: this.item ? this.item.avoid_fall : null,
                unclutch: this.item ? this.item.unclutch : null,
                size: this.item ? this.item.size : '',
                full_address: this.item ? this.item.full_address : '',
                memo: this.item ? this.item.memo : ''
            }
        };
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        resetModal() {},
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.onSubmit();
        },
        async onSubmit() {
            // Exit when the form isn't valid
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                return;
            }
            this.refreshData = true;
            if (this.item) {
                this.update();
            } else {
                this.create();
            }
        },
        create() {
            SiteRepository.create(this.form, this.site.id)
                .then(() => {
                    this.$toast.success(this.$t('sst.components.metalRolloutModal.notifications.add.success'));
                    this.$nextTick(() => {
                        this.$bvModal.hide(this.id);
                    });
                    eventBus.$emit('reloadSstMetalRolloutsTable');
                    eventBus.$emit('reloadSstBaf', this.mainRow.id);
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.error(this.$t('sst.components.metalRolloutModal.notifications.add.error'));
                    this.$nextTick(() => {
                        this.$bvModal.hide(this.id);
                    });
                });
        },
        update() {
            SiteRepository.update(this.form, this.item.id)
                .then(() => {
                    this.refreshData = false;
                    this.$toast.success(this.$t('sst.components.metalRolloutModal.notifications.edit.success'));
                    this.$nextTick(() => {
                        this.$bvModal.hide(this.id);
                    });
                    eventBus.$emit('reloadSstMetalRolloutsTable');
                    eventBus.$emit('reloadSstBaf', this.mainRow.id);
                })
                .catch(() => {
                    this.refreshData = false;
                    this.$toast.error(this.$t('sst.components.metalRolloutModal.notifications.edit.error'));
                    this.$nextTick(() => {
                        this.$bvModal.hide(this.id);
                    });
                });
        },
        closeModal() {
            this.$bvModal.hide(this.id);
        }
    }
};
</script>

<style scoped></style>
