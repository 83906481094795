<template>
    <b-card>
        <b-row class="mb-2">
            <b-col>
                <span v-html="infoText()"></span>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import moment from 'moment';

export default {
    name: 'SstPaidInvoiceTableDetails',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    methods: {
        getPaymentValidationDate() {
            return moment(this.row.item.payment_validation_date).format('DD/MM/YYYY');
        },
        infoText() {
            return this.$t('sst.components.paidInvoiceTableDetails.info', {
                paymentValidationDate: this.getPaymentValidationDate(),
                bapRef: this.$n(this.row.item.bap_ref, 'currency'),
                bapRefAmount: this.$n(this.row.item.bap_paid_amount, 'currency')
            });
        }
    }
};
</script>
