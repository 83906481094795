<template>
    <div>
        <b-button
            size="sm"
            variant="link"
            :title="$t('sst.components.bafTableActions.tooltips.details')"
            @click="row.toggleDetails"
            :key="row.item.id"
        >
            <b-icon :icon="row.detailsShowing ? 'eye-slash' : 'eye'" font-scale="1.5" variant="info"></b-icon>
        </b-button>
        <b-button
            class="d-inline"
            size="sm"
            variant="link"
            :title="$t('sst.components.bafTableActions.tooltips.cancel')"
            @click="deleteIntervention"
        >
            <b-icon icon="x-circle" variant="info"></b-icon>
        </b-button>
        <b-button size="sm" variant="link" :title="$t('sst.components.bafTableActions.tooltips.delete')" @click="cancelIntervention">
            <b-icon icon="trash" variant="info"></b-icon>
        </b-button>
    </div>
</template>

<script>
import { BafRepository } from '@/repositories';
import eventBus from '@/plugins/eventBus';

export default {
    name: 'SstBafTableActions',
    components: {},
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    methods: {
        deleteIntervention() {
            this.$bvModal
                .msgBoxConfirm(this.$t('sst.components.bafTableActions.confirmDeleteModal.message'), {
                    title: this.$t('sst.components.bafTableActions.confirmDeleteModal.title'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: this.$t('shared.general.buttons.validate'),
                    cancelVariant: 'danger',
                    cancelTitle: this.$t('shared.general.buttons.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value === true) {
                        console.log(this.row);
                        BafRepository.deleteIntervention(this.row.item.id)
                            .then(() => {
                                this.$toast.success(this.$t('sst.components.bafTableActions.notifications.delete.success'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                                eventBus.$emit('reloadSstBaf');
                            })
                            .catch(error => {
                                console.log(error);
                                this.$toast.error(this.$t('sst.components.bafTableActions.notifications.delete.error'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                            });
                    }
                })
                .catch(err => {
                    // An error occurred
                    console.log(err);
                });
        },
        cancelIntervention() {
            this.$bvModal
                .msgBoxConfirm(this.$t('sst.components.bafTableActions.confirmCancelModal.message'), {
                    title: this.$t('sst.components.bafTableActions.confirmCancelModal.title'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: this.$t('shared.general.buttons.validate'),
                    cancelVariant: 'danger',
                    cancelTitle: this.$t('shared.general.buttons.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value === true) {
                        BafRepository.cancelIntervention(this.row.item.id)
                            .then(() => {
                                this.$toast.success(this.$t('sst.components.bafTableActions.notifications.cancel.success'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                                eventBus.$emit('reloadSstBaf');
                            })
                            .catch(error => {
                                console.log(error);
                                this.$toast.error(this.$t('sst.components.bafTableActions.notifications.cancel.error'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                            });
                    }
                })
                .catch(err => {
                    // An error occurred
                    console.log(err);
                });
        }
    }
};
</script>

<style scoped></style>
