<template>
    <div>
        <!-- download button -->
        <b-button size="sm" variant="link" @click.prevent="downloadPdf()">
            <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
        </b-button>

        <!-- approve quotation modal -->
        <client-quotation-approve-modal @removeCurrentElement="removeCurrentElement()" :item="item">
        </client-quotation-approve-modal>

        <!-- cancel quotation modal -->
        <client-quotation-cancel-modal @removeCurrentElement="removeCurrentElement()" :item="item">
        </client-quotation-cancel-modal>
    </div>
</template>

<script>
import FileService from '@/services/FileService';
import { TABLE } from '@/constants';
import ClientQuotationApproveModal from '@/components/shared/table/modals/client/ClientQuotationApproveModal';
import ClientQuotationCancelModal from '@/components/shared/table/modals/client/ClientQuotationCancelModal';

export default {
    name: 'ClientQuotationTableActions',
    components: {
        ClientQuotationApproveModal,
        ClientQuotationCancelModal
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        removeCurrentElement() {
            this.$emit('removeCurrentElement');
        },
        downloadPdf() {
            const url = TABLE.PAGE.CLIENT.QUOTATION.PDF_URL.replace('{id}', this.item.id);
            const filename = TABLE.PAGE.CLIENT.QUOTATION.PDF_FILENAME.replace('{quoteId}', this.item.id);
            FileService.downloadFile(url, filename)
                .then(link => {
                    link.click();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('shared.components.customTable.notifications.errors.pdfNotFound'));
                });
        }
    }
};
</script>
