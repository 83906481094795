<template>
    <div>
        <div>
            <b-button
                size="sm"
                variant="link"
                :title="$t('sst.components.interventionTableActions.tooltips.details')"
                :to="{ name: 'SstInterventionsShow', params: { id: item.id } }"
            >
                <b-icon icon="eye" variant="info" font-scale="1.5"></b-icon>
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SstInterventionTableActions',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>
