<template>
    <b-card>
        <b-card-title title-tag="h5">{{ $t('sst.pages.oneIntervention.documentTypes.' + imageType) }}</b-card-title>

        <b-card-text class="small text-muted font-italic" v-if="imageList().length > 0">
            <span>{{ $t('sst.components.interventionImageSection.labels.numberOfImages', { nb: imageList().length }) }}</span
            >&nbsp;
            <a href="#"><b-icon icon="eye"></b-icon></a>
        </b-card-text>

        <div class="card-carousel" v-if="imageList().length > 0">
            <carousel :images="imageList()" />
        </div>

        <b-card-text v-if="imageList().length === 0">
            {{ $t('sst.components.interventionImageSection.labels.noImages') }}
        </b-card-text>

        <b-card-text v-if="waitingImages().length > 0">
            {{ $t('sst.components.interventionImageSection.labels.waitingImages', { waitingImages: waitingImages().length }) }}
        </b-card-text>

        <b-card-text v-if="canceledImages().length > 0">
            <b-row>
                <b-col v-if="canceledImages().length === 1">
                    {{ $t('sst.components.interventionImageSection.labels.canceledImage', { canceledImages: canceledImages().length }) }}
                </b-col>
                <b-col v-if="canceledImages().length > 1">
                    {{ $t('sst.components.interventionImageSection.labels.canceledImages', { canceledImages: canceledImages().length }) }}
                </b-col>
            </b-row>
        </b-card-text>
    </b-card>
</template>

<script>
import Carousel from '@/components/shared/element/CarouselImage';

export default {
    name: 'SstInterventionImageSection',
    components: {
        Carousel
    },
    props: {
        imageType: {
            type: String,
            required: true
        },
        images: {
            type: Array,
            required: false,
            default: function() {
                return [];
            }
        }
    },
    methods: {
        imageList() {
            if (this.images) {
                return this.images.filter(image => image.document.validation.validated === true);
            }
        },
        waitingImages() {
            if (this.images) {
                return this.images.filter(
                    image =>
                        image.document.validation.validated === false &&
                        (image.document.validation.cancellation_reason === 0 || image.document.validation.cancellation_reason === null)
                );
            }
        },
        canceledImages() {
            if (this.images) {
                return this.images.filter(
                    image => image.document.validation.validated === false && image.document.validation.cancellation_reason > 0
                );
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.card {
    overflow: hidden;

    &-carousel {
        margin-left: -$card-spacer-x;
        margin-right: -$card-spacer-x;
        margin-bottom: -$card-spacer-y;

        &:after {
            display: block;
            clear: both;
            content: '';
        }

        & + * {
            padding-top: $card-spacer-y * 2;
            display: block;
        }
    }
}

::v-deep .carousel {
    min-height: 100px;
}
</style>
