<template>
    <div>
        <!-- modal -->
        <b-modal
            :id="'new-intervention-modal'"
            ref="modal"
            :title="title"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            :ok-title="okButton"
            :cancel-title="cancelButton"
            :ok-variant="'danger'"
        >
            <validation-observer immediate ref="observer" v-slot="{ invalid, handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmitForm)">
                    <div>
                        <multiselect
                            :class="!form.site ? 'error-valid' : 'success-valid'"
                            :id="1"
                            v-model="form.site"
                            label="name"
                            track-by="name"
                            :options="options.site"
                            :multiple="false"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            :placeholder="formPlaceholder.site"
                            :select-label="''"
                            :deselect-label="''"
                            :closeOnSelect="true"
                            :required="true"
                        >
                            <template slot="noOptions">
                                <span>
                                    {{ $t('client.components.headerMultiselect.site.labels.noOptions') }}
                                </span>
                            </template>

                            <template slot="noResult">
                                <span>
                                    {{ $t('client.components.headerMultiselect.site.labels.noResults') }}
                                </span>
                            </template>
                        </multiselect>

                        <div class="multiselect-validation" v-if="!form.site">
                            {{ $t('client.components.interventionTableActions.modalNewIntervention.form.errors.siteRequired') }}
                        </div>
                    </div>

                    <br />

                    <validation-provider name="description" v-slot="validationContext" rules="required">
                        <b-form-group>
                            <b-form-textarea
                                id="description"
                                v-model="form.description"
                                :state="getValidationState(validationContext)"
                                :placeholder="formPlaceholder.description"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>

                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <br />

                    <validation-provider name="document" v-slot="validationContext">
                        <b-form-group>
                            <b-form-file
                                v-model="form.document"
                                :state="getValidationState(validationContext)"
                                accept="image/*, application/pdf"
                                :placeholder="formPlaceholder.document"
                                :drop-placeholder="formDropPlaceholder.document"
                            ></b-form-file>

                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <br />
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- button -->
        <b-button size="sm" class="mb-2 ml-2" v-b-modal="'new-intervention-modal'" :title="tooltip" @click.prevent="">
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            {{ $t('shared.components.customTable.buttons.createInterventionModal') }}
        </b-button>
    </div>
</template>

<script>
import { InterventionRepository } from '@/repositories';
import store from '../../../../../store';
import { CONTEXT } from '@/constants';
import FileHelper from '@/utils/fileHelper';


export default {
    name: 'ClientNewInterventionModal',
    data() {
        return {
            title: this.$t('client.components.interventionTableActions.modalNewIntervention.title'),
            tooltip: this.$t('client.components.interventionTableActions.tooltips.newIntervention'),
            okButton: this.$t('client.components.interventionTableActions.modalNewIntervention.buttons.submit'),
            cancelButton: this.$t('client.components.interventionTableActions.modalNewIntervention.buttons.cancel'),
            form: {
                site: null,
                description: '',
                document: null
            },
            formPlaceholder: {
                site: this.$t('client.components.headerMultiselect.site.labels.chooseSite'),
                description: this.$t('client.components.interventionTableActions.modalNewIntervention.form.description.placeholder'),
                document: this.$t('client.components.interventionTableActions.modalNewIntervention.form.document.placeholder'),
            },
            formDropPlaceholder: {
                document: this.$t('client.components.interventionTableActions.modalNewIntervention.form.document.dropPlaceholder'),
            },
            options: {
                [CONTEXT.Site]: store.getters['context/getSites']
            },
            context: CONTEXT
        };
    },
    methods: {
        resetModal() {
            this.form.site = null;
            this.form.description = '';
            this.form.document = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();

            // Trigger submit handler
            this.onSubmitForm();
        },
        async onSubmitForm() {
            // Exit when the form isn't valid
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                return;
            }

            if (!this.form.site) {
                return;
            }

            const { site, description, document } = this.form;

            let formData = new FormData;
            formData.append('site', site.id);
            formData.append('description', description);

            if (document) {
                let file = document instanceof File ? document : FileHelper.dataURLtoFile(document.dataUrl, document.info.name);
                formData.append('document', file);
            }

            InterventionRepository.newInterventionRequest(formData)
                .then(() => {
                    this.$toast.success(this.$t('client.components.interventionTableActions.notifications.success.create'));
                    this.$nextTick(() => {
                        this.$bvModal.hide('new-intervention-modal');
                    });
                })
                .catch(() => {
                    this.$toast.error(this.$t('client.components.interventionTableActions.notifications.errors.create'));
                });
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        }
    }
};
</script>

<style lang="scss">
.modal-content {
    .multiselect {
        &__tags {
            min-height: 40px !important;
            padding: 6px 40px 0 6px !important;
        }
    }

    .multiselect-validation {
        width: 100%;
        margin-bottom: 0.25rem;
        font-size: 80%;
        color: #d80808;
    }

    .error-valid {
        border-radius: 0.3rem;
        border: 1px solid #d80808 !important;
    }

    .success-valid {
        border-radius: 0.3rem;
        border: 1px solid #28a745 !important;
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: 'Parcourir';
    }
}
</style>
