<template>
    <div v-if="!item.approved_at">
        <!-- approve quotation modal -->
        <client-quotation-approve-modal @removeCurrentElement="removeCurrentElement()" :item="item">
        </client-quotation-approve-modal>
    </div>
</template>

<script>
import ClientQuotationApproveModal from '@/components/shared/table/modals/client/ClientQuotationApproveModal';

export default {
    name: 'ClientTreatedQuotationTableActions',
    components: {
        ClientQuotationApproveModal
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        removeCurrentElement() {
            this.$emit('removeCurrentElement');
        }
    }
};
</script>
