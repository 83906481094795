<template>
    <div>
        <b-carousel
            id="carousel-1"
            class="carousel"
            v-model="slide"
            :interval="interval"
            controls
            indicators
            :background="background"
            :img-width="imgWidth"
            :img-height="imgHeight"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
        >
            <b-carousel-slide v-for="(image, label) in images" :key="label" :img-src="image.url + '?w=300'"> </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
export default {
    name: 'CarouselImage',
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            slide: 0,
            sliding: null,
            imgWidth: 300,
            imgHeight: 300,
            background: '#ababab',
            interval: 4000
        };
    },
    methods: {
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        }
    }
};
</script>

<style scoped></style>
