<template>
    <div>
        <div v-if="!refreshData">
            <b-button pill variant="primary" v-b-modal="'modal-metal-rollout-create'" class="mt-2 mb-3">
                {{ $t('sst.pages.metalRollout.buttons.add') }}
            </b-button>
            <b-table
                :striped="striped"
                :bordered="bordered"
                :borderless="borderless"
                :outlined="outlined"
                :small="small"
                :hover="hover"
                :dark="dark"
                :fixed="fixed"
                :foot-clone="footClone"
                :no-border-collapse="noCollapse"
                :items="items"
                :fields="fields"
                :head-variant="headVariant"
                :table-variant="tableVariant"
                :empty-text="$t('client.components.interventionMetalRolloutsDetails.table.labels.noData')"
                show-empty
                stacked="md"
            >
                <template v-slot:cell(actions)="{ item }">
                    <b-button
                        class="d-inline"
                        size="sm"
                        variant="link"
                        :title="$t('sst.components.bafTableDetails.tooltips.edit')"
                        v-b-modal="'modal-metal-rollout' + item.id"
                    >
                        <b-icon icon="pencil" variant="info"></b-icon>
                    </b-button>
                    <b-button
                        size="sm"
                        variant="link"
                        @click="deleteMetalRollout(item.id)"
                        :title="$t('sst.components.bafTableDetails.tooltips.delete')"
                    >
                        <b-icon icon="trash" variant="info"></b-icon>
                    </b-button>

                    <sst-metal-rollout-modal
                        :mainRow="row.item"
                        :item="item"
                        :access-enum-options="accessEnumOptions"
                        :axe-type-enum-options="axeTypeEnumOptions"
                        :id="'modal-metal-rollout' + item.id"
                        :title="$t('sst.pages.metalRollout.modal.edit.title')"
                    ></sst-metal-rollout-modal>
                </template>
            </b-table>
            <sst-metal-rollout-modal
                :mainRow="row.item"
                :id="'modal-metal-rollout-create'"
                :access-enum-options="accessEnumOptions"
                :axe-type-enum-options="axeTypeEnumOptions"
                :site="this.row.item.site"
                :title="$t('sst.pages.metalRollout.modal.create.title')"
            >
            </sst-metal-rollout-modal>
            <div v-if="purchase">
                <b-container>
                    <b-card no-body class="mb-3">
                        <b-card-header header-bg-variant="white">
                            <b-card-title title-tag="h5" class="mt-1 mb-0">Documents</b-card-title>
                            <dropdown-group
                                @clickDropdownOption="clickDropdownOptionGroupBtn"
                                :dropdownGroupParams="dropdownGroupParams"
                                class="card-header-actions"
                            ></dropdown-group>
                            <image-uploader
                                :maxWidth="fileConstants.MAX_WIDTH"
                                :quality="fileConstants.QUALITY"
                                :autoRotate="true"
                                outputFormat="verbose"
                                :preview="false"
                                :capture="false"
                                doNotResize="['gif', 'svg', 'pdf']"
                                @change="uploadFile"
                                class="d-none"
                                ref="file"
                                id="fileInput"
                            ></image-uploader>
                        </b-card-header>
                    </b-card>

                    <b-card-group columns>
                        <sst-intervention-document-section
                            v-if="purchase.bi_document"
                            :documentType="interventionConstants.DOCUMENT_TYPES.BI"
                            :document="purchase.bi_document"
                            :purchaseId="purchase.id"
                        ></sst-intervention-document-section>

                        <sst-intervention-image-section
                            :imageType="interventionConstants.DOCUMENT_TYPES.PICS_BEFORE"
                            :images="purchase.pictures.pre_intervention"
                        ></sst-intervention-image-section>

                        <sst-intervention-image-section
                            :imageType="interventionConstants.DOCUMENT_TYPES.PICS_AFTER"
                            :images="purchase.pictures.post_intervention"
                        ></sst-intervention-image-section>
                    </b-card-group>
                </b-container>
            </div>
        </div>
        <spinner v-if="refreshData"></spinner>
    </div>
</template>

<script>
import SstMetalRolloutModal from '@/components/sst/metal-rollout/SstMetalRolloutModal';
import { SiteRepository, HelperRepository, SstRepository } from '@/repositories';
import Spinner from '@/components/shared/element/Spinner';
import eventBus from '@/plugins/eventBus';
import { INTERVENTION } from '@/constants/sst/intervention';
import DropdownGroup from '@/components/shared/element/DropdownGroup';
import SstInterventionImageSection from '@/components/sst/intervention/SstInterventionImageSection';
import SstInterventionDocumentSection from '@/components/sst/intervention/SstInterventionDocumentSection';
import FileHelper from '@/utils/fileHelper';
import { FILE } from '@/constants';


export default {
    name: 'SstBafTableDetails',
    components: {
        SstMetalRolloutModal,
        Spinner,
        DropdownGroup,
        SstInterventionImageSection,
        SstInterventionDocumentSection
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fields: [
                {
                    key: 'full_address',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.fullAddress')
                },
                {
                    key: 'size',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.size')
                },
                {
                    key: 'axe_access_display',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.axeAccess')
                },
                {
                    key: 'engine_access_display',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.engineAccess')
                },
                {
                    key: 'material',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.material')
                },
                {
                    key: 'avoid_fall_display',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.avoidFall')
                },
                {
                    key: 'unclutch_display',
                    label: this.$t('client.components.interventionMetalRolloutsDetails.table.columns.unclutch')
                },
                {
                    key: 'actions',
                    label: this.$t('sst.pages.metalRollout.table.columns.actions'),
                    sortable: false,
                    class: 'text-left'
                }
            ],
            striped: false,
            bordered: true,
            borderless: false,
            outlined: false,
            small: true,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,
            headVariant: 'dark',
            tableVariant: '',
            noCollapse: false,
            refreshData: false,
            items: [],
            accessEnumOptions: [],
            axeTypeEnumOptions: [],
            purchase: null,
            dropdownGroupParams: {
                title: this.$t('sst.pages.oneIntervention.groupDropdown.labels.actions'),
                disabled: false,
                right: true,
                size: 'sm',
                variant: 'primary',
                groupOptions: [
                    {
                        title: this.$t('sst.pages.oneIntervention.groupDropdown.labels.upload'),
                        id: INTERVENTION.ACTIONS.UPLOAD,
                        options: []
                    }
                ]
            },
            interventionConstants: INTERVENTION,
            documentTypeUpload: null,
            fileConstants: FILE
        };
    },
    created() {
        eventBus.$on('reloadSstMetalRolloutsTable', () => {
            this.data();
        });
        this.enums().then(() => {
            this.data();
        });
    },
    beforeDestroy() {
        eventBus.$off('reloadSstMetalRolloutsTable');
    },
    methods: {
        data() {
            this.items = [];
            this.refreshData = true;
            if (this.row.item.site) {
                SiteRepository.metalRolloutsBySite(this.row.item.site.id).then(response => {
                    this.items = response.data.data;
                    if (this.row.item.purchase) {
                        this.purchase = null;
                        SstRepository.getPurchase(this.row.item.purchase.id)
                            .then(response => {
                                this.refreshData = false;
                                this.purchase = response.data.data;
                                this.getUploadOptions();
                            })
                            .catch(error => {
                                this.refreshData = false;
                                console.log(error);
                            });
                    }
                });
            } else {
                this.refreshData = false;
            }
        },
        enums() {
            this.refreshData = true;
            return new Promise((resolve, reject) => {
                HelperRepository.getEnums('metalRolloutAccess')
                    .then(response => {
                        this.accessEnumOptions = response.data.data;
                        this.accessEnumOptions.unshift({
                            id: null,
                            text: this.$t('client.components.quotationTableAction.form.reason.selectOption')
                        });
                        HelperRepository.getEnums('metalRolloutAxeType').then(response => {
                            this.axeTypeEnumOptions = response.data.data;
                            this.axeTypeEnumOptions.unshift({
                                id: null,
                                text: this.$t('client.components.quotationTableAction.form.reason.selectOption')
                            });
                            resolve();
                            this.refreshData = false;
                        });
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        deleteMetalRollout(metalRolloutId) {
            this.$bvModal
                .msgBoxConfirm(this.$t('sst.components.bafTableDetails.confirmDeleteModal.message'), {
                    title: this.$t('sst.components.bafTableDetails.confirmDeleteModal.title'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    okTitle: this.$t('shared.general.buttons.validate'),
                    cancelVariant: 'danger',
                    cancelTitle: this.$t('shared.general.buttons.cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value === true) {
                        SiteRepository.delete(metalRolloutId)
                            .then(() => {
                                this.$toast.success(this.$t('sst.components.bafTableDetails.notifications.delete.success'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                                eventBus.$emit('reloadSstMetalRolloutsTable');
                                eventBus.$emit('reloadSstBaf', this.row.item.id);
                            })
                            .catch(error => {
                                console.log(error);
                                this.$toast.error(this.$t('sst.components.bafTableDetails.notifications.delete.error'));
                                this.$nextTick(() => {
                                    this.$bvModal.hide(this.id);
                                });
                            });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getUploadOptions() {
            this.dropdownGroupParams.groupOptions[0].options = [];
            if (
                !this.purchase.pictures.pre_intervention ||
                (this.purchase.pictures.pre_intervention &&
                    this.purchase.pictures.pre_intervention.filter(item => item.document.validation.validated === true).length +
                        this.purchase.pictures.pre_intervention.filter(
                            item => item.document.validation.validated === false && item.document.validation.cancellation_reason === null
                        ).length <
                        INTERVENTION.SELECT_BOX_CONDITIONS.PICTURES)
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_BEFORE,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.PICS_BEFORE')
                });
            }
            if (
                !this.purchase.bi_document ||
                (this.purchase.bi_document.validation.validated === false && this.purchase.bi_document.validation.cancellation_reason > 0)
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.BI,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.BI')
                });
            }

            if (
                !this.purchase.pictures.post_intervention ||
                (this.purchase.pictures.post_intervention &&
                    this.purchase.pictures.post_intervention.filter(item => item.document.validation.validated === true).length +
                        this.purchase.pictures.post_intervention.filter(
                            item => item.document.validation.validated === false && item.document.validation.cancellation_reason === null
                        ).length <
                        INTERVENTION.SELECT_BOX_CONDITIONS.PICTURES)
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_AFTER,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.PICS_AFTER')
                });
            }
        },
        clickDropdownOptionGroupBtn({ optionId }) {
            this.documentTypeUpload = optionId;
            let fileInputElement = document.getElementById('fileInput');
            if (
                this.documentTypeUpload === INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_AFTER ||
                this.documentTypeUpload === INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_BEFORE
            ) {
                fileInputElement.accept = 'image/*';
            } else {
                fileInputElement.accept = 'image/*,application/pdf';
            }
            fileInputElement.click();
        },
        uploadFile(event) {
            const formData = new FormData();
            let file = event instanceof File ? event : FileHelper.dataURLtoFile(event.dataUrl, event.info.name);
            formData.append('document_type', this.documentTypeUpload);
            formData.append('document', file);
            SstRepository.uploadDocument(this.purchase.id, formData)
                .then(() => {
                    this.$toast.success(this.$i18n.t('sst.pages.oneIntervention.uploadDropdown.notifications.success'));
                    eventBus.$emit('reloadSstMetalRolloutsTable');
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.error(this.$i18n.t('sst.pages.oneIntervention.uploadDropdown.notifications.error'));
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.card-header {
    padding-right: 85px;
    position: relative;

    &-actions {
        position: absolute;
        right: 6px;
        top: 12px;
    }
}
</style>
