<template>
    <div>
        <b-row class="mt-1 mb-1">
            <b-col>
                <div v-if="tableConfig.displayCount">
                    <h4>
                        <b-badge variant="light">
                            {{ $t('shared.components.customTable.labels.resultsFound', { rows: totalRows }) }}
                        </b-badge>
                    </h4>
                </div>
            </b-col>

            <b-col class="text-center">
                <div v-if="totalAmount">
                    <h4>
                        <b-badge variant="light">
                            {{ $t('shared.components.customTable.labels.totalAmount', { amount: $n(totalAmountLocal, 'currency') }) }}
                        </b-badge>
                    </h4>
                </div>
            </b-col>

            <b-col class="text-right">
                <div>
                    <client-new-intervention-modal v-if="displayBtnNewIntervention()" class="d-inline-block" />
                    <b-button size="sm" class="d-inline-block mb-2 ml-2" @click.prevent="downloadExcel()" v-if="displayDownloadExcelLink()">
                        <b-icon icon="table" aria-hidden="true"></b-icon> {{ $t('shared.components.customTable.buttons.exportExcel') }}
                    </b-button>
                </div>
            </b-col>
        </b-row>

        <b-row class="mb-1">
            <b-col md>
                <b-form-group v-if="tableConfig.radioButtonsOptions">
                    <b-form-radio-group
                        :options="tableConfig.radioButtonsOptions"
                        button-variant="outline-danger"
                        buttons
                        id="btn-radios-1"
                        name="radios-btn-default"
                        v-model="tableConfig.radioButtonSelectedOption"
                        @input="radioButtonEventChange"
                    />
                </b-form-group>

                <b-form-select
                    :options="tableConfig.selectBoxOptions"
                    @change="selectBoxValueEvent"
                    v-model="tableConfig.selectedOptionSelectBox"
                    v-if="tableConfig.selectBoxOptions"
                    class="mb-3 w-50"
                />
            </b-col>
            <b-col md>
                <b-input-group size="md" class="mb-2">
                    <b-form-input
                        v-model="filterLocal"
                        type="search"
                        id="filterInput"
                        :placeholder="$t('shared.components.customTable.labels.search')"
                    />
                    <b-input-group-append>
                        <b-button :disabled="!filterLocal" @click="filterLocal = ''">
                            <b-icon icon="x" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mb-2" v-if="tableConfig.displayTotalRows">
            <b-col md>
                <h3 v-if="totalRows > 0">
                    <b-badge variant="light" v-if="page === table.PAGE.CLIENT.INTERVENTION.ID || page === table.PAGE.SST.INTERVENTION.ID">
                        {{ $t('shared.components.customTable.labels.totalInterventions', { rows: totalRows }) }}
                    </b-badge>
                    <b-badge variant="light" v-if="page === table.PAGE.SST.INVOICE.PENDING.ID || page === table.PAGE.SST.INVOICE.PAID.ID">
                        {{ $t('shared.components.customTable.labels.totalInvoices', { rows: totalRows }) }}
                    </b-badge>
                </h3>
                <h3 v-else>
                    <b-badge variant="light">
                        {{ $t('shared.components.customTable.labels.noResultat') }}
                    </b-badge>
                </h3>
            </b-col>
        </b-row>

        <b-table
            id="dataTable"
            :striped="tableConfig.striped"
            :bordered="tableConfig.bordered"
            :items="items"
            :fields="fieldsLocal"
            :head-variant="tableConfig.headVariant"
            :sort-by.sync="sortByLocal"
            :sort-desc.sync="sortDescLocal"
            :per-page="perPageLocal"
            :current-page="currentPageLocal"
            :filter="filterLocal"
            :empty-text="$t('shared.components.customTable.labels.noData')"
            show-empty
            borderless
            stacked="md"
            :empty-filtered-text="$t('shared.components.customTable.labels.noData')"
            @filtered="onFiltered"
            @sort-changed="onSortChanged"
        >
            <template v-slot:cell(download)="{ item }">
                <b-button size="sm" variant="link" @click.prevent="downloadPdf(item)">
                    <b-icon icon="cloud-download" aria-hidden="true" variant="info"></b-icon>
                </b-button>
            </template>

            <template slot="empty" slot-scope="scope">
                <h4>{{ scope.emptyText }}</h4>
            </template>

            <template v-slot:cell(actions)="{ item }">
                <div v-if="page === table.PAGE.CLIENT.QUOTATION.ID">
                    <client-quotation-table-actions @removeCurrentElement="removeCurrentElement(item)" :item="item" />
                </div>
                <div v-if="page === table.PAGE.CLIENT.TREATED_QUOTATION.ID">
                    <client-treated-quotation-table-actions @removeCurrentElement="removeCurrentElement(item)" :item="item" />
                </div>
                <div v-if="page === table.PAGE.SST.INTERVENTION.ID">
                    <sst-intervention-table-actions :item="item" />
                </div>
                <div v-if="page === table.PAGE.SST.METAL_ROLLOUT.ID">
                    <sst-metal-rollout-table-actions :item="item" />
                </div>
                <div v-if="page === table.PAGE.SST.PREVIOUS_BAF.ID">
                    <sst-previous-baf-table-actions :row="item" />
                </div>
            </template>

            <template v-slot:cell(checkbox)="{ item }">
                <sst-baf-quotation-checkbox-action :item="item" />
            </template>

            <template v-slot:cell(html)="{ item }">
                <span v-html="item.html"></span>
            </template>

            <!-- DETAILS BUTTON if fields contain show_details  -->
            <template v-slot:cell(show_details)="row">
                <div v-if="page === table.PAGE.SST.INVOICE.PENDING.ID">
                    <sst-pending-invoice-details-button :row="row" />
                </div>
                <div v-else-if="page === table.PAGE.CLIENT.INTERVENTION.ID || page === table.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.ID">
                    <client-document-intervention-details-button :row="row" />
                </div>
                <div v-else-if="page === table.PAGE.SST.BAF.ID">
                    <sst-baf-table-actions :row="row" />
                </div>
                <div v-else>
                    <b-button size="sm" variant="link" class="mb-2" @click="row.toggleDetails">
                        <b-icon :icon="row.detailsShowing ? 'eye-slash' : 'eye'" font-scale="1.5" variant="info" />
                    </b-button>
                </div>
            </template>
            <template v-slot:row-details="row">
                <sst-paid-invoice-table-details :row="row" v-if="page === table.PAGE.SST.INVOICE.PAID.ID" />
                <sst-pending-invoice-table-details :row="row" v-if="page === table.PAGE.SST.INVOICE.PENDING.ID" />
                <client-document-intervention-table-details :row="row" v-if="page === table.PAGE.CLIENT.INTERVENTION.ID" />
                <client-intervention-metal-rollouts-details :row="row" v-if="page === table.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.ID" />
                <sst-baf-table-details :row="row" v-if="page === table.PAGE.SST.BAF.ID" />
            </template>
            <!-- END Details BUTTON -->
        </b-table>
        <div v-if="tableConfig.displayAddBtn">
            <sst-current-baf-add-new-row v-if="page === table.PAGE.SST.BAF.ID" :items="items" />
        </div>
        <b-pagination
            v-model="currentPageLocal"
            :total-rows="totalRows"
            :per-page="perPageLocal"
            aria-controls="dataTable"
            align="right"
            pills
        />
    </div>
</template>

<script>
import moment from 'moment';
import { TABLE } from '@/constants';
import ClientQuotationTableActions from '@/components/shared/table/actions/client/ClientQuotationTableActions';
import ClientTreatedQuotationTableActions from '@/components/shared/table/actions/client/ClientTreatedQuotationTableActions';
import _ from 'lodash';
import SstPaidInvoiceTableDetails from '@/components/shared/table/details-card/sst/SstPaidInvoiceTableDetails';
import SstPendingInvoiceTableDetails from '@/components/shared/table/details-card/sst/SstPendingInvoiceTableDetails';
import SstPendingInvoiceDetailsButton from '@/components/shared/table/details-button/sst/SstPendingInvoiceDetailsButton';
import SstInterventionTableActions from '@/components/shared/table/actions/sst/SstInterventionTableActions';
import ClientDocumentInterventionDetailsButton from '@/components/shared/table/details-button/client/ClientInterventionDetailsButton.vue';
import ClientDocumentInterventionTableDetails from '@/components/shared/table/details-card/client/ClientInterventionTableDetails';
import ClientInterventionMetalRolloutsDetails from '@/components/shared/table/details-card/client/ClientInterventionMetalRolloutsDetails';
import SstBafTableDetails from '@/components/shared/table/details-card/sst/SstBafTableDetails';
import FileService from '@/services/FileService';
import SstMetalRolloutTableActions from '@/components/shared/table/actions/sst/SstMetalRolloutTableActions';
import SstBafQuotationCheckboxAction from '@/components/shared/table/actions/sst/SstBafQuotationCheckboxAction';
import SstBafTableActions from '@/components/shared/table/actions/sst/SstBafTableActions';
import SstPreviousBafTableActions from '@/components/shared/table/actions/sst/SstPreviousBafTableActions';
import SstCurrentBafAddNewRow from '@/components/shared/table/actions/sst/SstCurrentBafAddNewRow';
import ClientNewInterventionModal from '@/components/shared/table/modals/client/ClientNewInterventionModal';
import store from '@/store';

export default {
    name: 'CustomTable',
    components: {
        SstInterventionTableActions,
        SstPaidInvoiceTableDetails,
        SstPendingInvoiceTableDetails,
        SstPendingInvoiceDetailsButton,
        SstMetalRolloutTableActions,
        SstBafQuotationCheckboxAction,
        SstBafTableActions,
        SstBafTableDetails,
        ClientDocumentInterventionDetailsButton,
        ClientDocumentInterventionTableDetails,
        ClientInterventionMetalRolloutsDetails,
        ClientQuotationTableActions,
        ClientTreatedQuotationTableActions,
        SstPreviousBafTableActions,
        SstCurrentBafAddNewRow,
        ClientNewInterventionModal
    },
    props: {
        tableConfig: {
            sortBy: {
                type: String,
                required: false,
                default: null
            },
            sortDesc: {
                type: String,
                required: false,
                default: null
            },
            perPage: {
                type: Number,
                required: false,
                default: 50
            },
            currentPage: {
                type: Number,
                required: false,
                default: 1
            },
            filter: {
                type: String,
                required: false,
                default: null
            },
            displayCount: {
                type: Boolean,
                required: false,
                default: true
            },
            displayTotalRows: {
                type: Boolean,
                required: false,
                default: true
            },
            radioButtonsOptions: {
                type: Array,
                required: false,
                default: function() {
                    return [];
                }
            },
            radioButtonSelectedOption: {
                type: String,
                required: false,
                default: null
            },
            selectBoxOptions: {
                type: Array,
                required: false,
                default: function() {
                    return [];
                }
            },
            selectedOptionSelectBox: {
                type: String,
                required: false,
                default: null
            },
            displayAddBtn: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        items: {
            type: Array,
            required: false
        },
        fields: {
            type: Array,
            required: false
        },
        totalAmount: {
            type: [Number, String],
            required: false,
            default: null
        },
        page: {
            type: String,
            required: true
        },
        forcedTableRefresh: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        if (this.page === TABLE.PAGE.SST.INTERVENTION.ID) {
            if (store.getters['interventionTableState/filteredItemsLength']) {
                this.totalRows = store.getters['interventionTableState/filteredItemsLength'];
            }
        }
    },
    data() {
        return {
            fieldsLocal: this.addFormatters(),
            sortByLocal: this.tableConfig.sortBy,
            sortDescLocal: this.tableConfig.sortDesc,
            perPageLocal: this.tableConfig.perPage || 50,
            currentPageLocal: this.tableConfig.currentPage || 1,
            filterLocal: this.tableConfig.filter,
            table: TABLE,
            totalAmountLocal: this.totalAmount,
            totalRows: this.items.length
        };
    },
    watch: {
        currentPageLocal(newValue) {
            this.$emit('pageChanged', newValue);
        }
    },
    methods: {
        addFormatters() {
            const dateFields = [
                'request_date',
                'date',
                'alert_date',
                'sst_bill_record_date',
                'bap_paid_date',
                'payment_validation_date',
                'sst_bill_record_date',
                'created_at',
                'invoice_date'
            ];
            const amountFields = ['ttc_amount', 'debt_ttc_amount', 'ht_amount', 'bap_paid_amount'];

            this.fields.forEach(field => {
                if (dateFields.includes(field.key)) {
                    field.formatter = 'dateFormat';
                }
                if (amountFields.includes(field.key)) {
                    field.formatter = 'priceFormat';
                }
            });
            return this.fields;
        },
        dateFormat(value) {
            if (value) {
                return moment(value).format('DD/MM/YYYY');
            }
            return '';
        },
        priceFormat(value) {
            return this.$i18n.n(value, 'currency');
        },
        downloadExcel() {
            const filters = {
                global_filters: this.$store.getters['context/getGlobalFiltersIds'],
                ...this.$store.getters['periodFilter/getStartAndEndDateFilters']
            };
            if (this.page === TABLE.PAGE.CLIENT.INTERVENTION.ID) {
                filters.status = this.tableConfig.radioButtonSelectedOption;
            }
            FileService.downloadExcel(this.getExcelDownloadUrl(), this.getExcelFilename(), filters)
                .then(link => {
                    link.click();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('shared.components.customTable.notifications.errors.pdfNotFound'));
                });
        },
        downloadPdf(item) {
            FileService.downloadFile(this.getPdfDownloadUrl(item), this.getPdfFilename(item))
                .then(link => {
                    link.click();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('shared.components.customTable.notifications.errors.pdfNotFound'));
                });
        },
        getExcelDownloadUrl() {
            switch (this.page) {
                case TABLE.PAGE.CLIENT.QUOTATION.ID:
                    return TABLE.PAGE.CLIENT.QUOTATION.EXCEL_URL;
                case TABLE.PAGE.CLIENT.INVOICE.ID:
                    return TABLE.PAGE.CLIENT.INVOICE.EXCEL_URL;
                case TABLE.PAGE.CLIENT.INTERVENTION.ID:
                    return TABLE.PAGE.CLIENT.INTERVENTION.EXCEL_URL;
                case TABLE.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.ID:
                    return TABLE.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.EXCEL_URL;
            }
        },
        getPdfDownloadUrl(item) {
            switch (this.page) {
                case TABLE.PAGE.CLIENT.QUOTATION.ID:
                case TABLE.PAGE.CLIENT.TREATED_QUOTATION.ID:
                    return TABLE.PAGE.CLIENT.QUOTATION.PDF_URL.replace('{id}', item.id);
                case TABLE.PAGE.CLIENT.INVOICE.ID:
                    return TABLE.PAGE.CLIENT.INVOICE.PDF_URL.replace('{id}', item.ref);
            }
        },
        removeCurrentElement(item) {
            this.items.splice(
                this.items.findIndex(element => {
                    return element.id === item.id;
                }),
                1
            );
            this.totalRows -= 1;
            this.updateTotalAmount();
        },
        updateTotalAmount() {
            switch (this.page) {
                case TABLE.PAGE.CLIENT.QUOTATION.ID:
                    this.totalAmountLocal = _.sumBy(this.items, item => {
                        return _.floor(item.ht_amount, 2);
                    });
                    break;
            }
        },
        displayDownloadExcelLink() {
            switch (this.page) {
                case TABLE.PAGE.CLIENT.INTERVENTION.ID:
                case TABLE.PAGE.CLIENT.QUOTATION.ID:
                case TABLE.PAGE.CLIENT.INVOICE.ID:
                case TABLE.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.ID:
                    return true;
                default:
                    return false;
            }
        },
        displayBtnNewIntervention() {
            switch (this.page) {
                case TABLE.PAGE.CLIENT.INTERVENTION.ID:
                    return true;
                default:
                    return false;
            }
        },
        getPdfFilename(item) {
            switch (this.page) {
                case TABLE.PAGE.CLIENT.QUOTATION.ID:
                case TABLE.PAGE.CLIENT.TREATED_QUOTATION.ID:
                    return TABLE.PAGE.CLIENT.QUOTATION.PDF_FILENAME.replace('{quoteId}', item.id);
                case TABLE.PAGE.CLIENT.INVOICE.ID:
                    return TABLE.PAGE.CLIENT.INVOICE.PDF_FILENAME.replace('{quoteNumber}', item.id);
            }
        },
        getExcelFilename() {
            switch (this.page) {
                case TABLE.PAGE.CLIENT.QUOTATION.ID:
                case TABLE.PAGE.CLIENT.TREATED_QUOTATION.ID:
                    return TABLE.PAGE.CLIENT.QUOTATION.EXCEL_FILENAME.replace('{date}', moment().format('YYYYMMDD'));
                case TABLE.PAGE.CLIENT.INVOICE.ID:
                    return TABLE.PAGE.CLIENT.INVOICE.EXCEL_FILENAME.replace('{date}', moment().format('YYYYMMDD'));
                case TABLE.PAGE.CLIENT.INTERVENTION.ID:
                    return TABLE.PAGE.CLIENT.INTERVENTION.EXCEL_FILENAME
                        .replace('{status}', this.$t('client.pages.interventionList.filterStatus.' + this.tableConfig.radioButtonSelectedOption))
                        .replace('{date}', moment().format('DD-MM-YYYY'));
                case TABLE.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.ID:
                    return TABLE.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.EXCEL_FILENAME.replace('{date}', moment().format('YYYYMMDD'));
            }
        },
        radioButtonEventChange(selectedOption) {
            this.$emit('radioButtonTableFilterChange', selectedOption, this.filterLocal);
        },
        selectBoxValueEvent(selectedOption) {
            this.$emit('selectBoxOptionChange', selectedOption);
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.$emit('tableFiltered', this.filterLocal, this.totalRows);
            this.currentPageLocal = 1;
        },
        onSortChanged(event) {
            this.$emit('sortingColumnChanged', event.sortBy, event.sortDesc);
        }
    },
    updated() {
        if (this.forcedTableRefresh) {
            this.onFiltered(this.items);
            this.$emit('toggleForcedTableRefresh');
        }
    }
};
</script>
