<template>
    <div>
        <b-button
            size="sm"
            target="_blank"
            variant="link"
            :to="{ name: 'SstBafOnePrevious', params: { id: this.row.id } }"
            :title="$t('sst.components.bafTableActions.tooltips.details')"
            :key="row.id"
        >
            <b-icon :icon="'eye'" font-scale="1.5" variant="info"></b-icon>
        </b-button>
    </div>
</template>

<script>
export default {
    name: 'SstPreviousBafTableActions',
    components: {},
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    methods: {}
};
</script>

<style scoped></style>
