<template>
    <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.comment variant="secondary" v-if="data.length > 0">
                {{ $t('client.components.interventionCommentCard.comment') }}
                <b-badge class="mr-2" variant="light" pill>{{ data.length }}</b-badge>
                <span class="when-opened">
                    <b-icon icon="arrows-collapse" aria-hidden="true"></b-icon>
                </span>
                <span class="when-closed">
                    <b-icon icon="arrows-expand" aria-hidden="true"></b-icon>
                </span>
            </b-button>
            <b-button block v-b-toggle.comment variant="secondary" v-if="data.length === 0" disabled>
                {{ $t('client.components.interventionCommentCard.notFoundComments') }}
            </b-button>
        </b-card-header>
        <b-collapse id="comment" visible accordion="my-accordion" role="tabpanel" v-if="data.length > 0">
            <b-card-body>
                <b-card-text>
                    <b-list-group>
                        <b-list-group-item
                            class="d-flex justify-content-between align-items-center"
                            v-for="(comment, label) in data"
                            :key="label"
                        >
                            {{
                                $t('client.components.interventionCommentCard.label', {
                                    createdAt: $options.filters.formatDate(comment.created_at),
                                    fullname: comment.author.fullname,
                                    text: comment.text
                                })
                            }}
                        </b-list-group-item>
                    </b-list-group>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
export default {
    name: 'ClientInterventionCommentCard',
    props: {
        data: {
            type: Array,
            required: true
        }
    }
};
</script>
