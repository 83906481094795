<template>
    <div>
        <b-button pill variant="danger" v-b-modal="'modal-create'">
            {{ $t('sst.components.bafCurrentAddNewItem.buttons.add') }}
        </b-button>
        <b-modal
            :title="$t('sst.components.bafCurrentAddNewItem.modal.title')"
            id="modal-create"
            size="lg"
            ref="modal"
            @hidden="resetModal"
            @ok="handleOk"
            :ok-title="$t('sst.components.bafCurrentAddNewItem.modal.buttons.save')"
            :cancel-title="$t('sst.components.bafCurrentAddNewItem.modal.buttons.cancel')"
            :ok-variant="'primary'"
            :cancel-variant="'secondary'"
            :centered="true"
        >
            <validation-observer immediate ref="observer" v-slot="{ invalid, handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <validation-provider
                        :name="$t('sst.components.bafCurrentAddNewItem.modal.labels.invoice_date')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group
                            :label="$t('sst.components.bafCurrentAddNewItem.modal.labels.invoice_date')"
                            label-cols-sm="4"
                            label-cols-lg="3"
                        >
                            <b-form-datepicker
                                id="invoice_date"
                                name="invoice_date"
                                v-model="form.invoice_date"
                                placeholder=""
                                locale="fr"
                                start-weekday="1"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :state="getValidationState(validationContext)"
                            ></b-form-datepicker>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.components.bafCurrentAddNewItem.modal.labels.invoice_ref')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group
                            :label="$t('sst.components.bafCurrentAddNewItem.modal.labels.invoice_ref')"
                            label-cols-sm="4"
                            label-cols-lg="3"
                        >
                            <b-form-input
                                id="invoice_ref"
                                name="invoice_ref"
                                v-model="form.invoice_ref"
                                :state="getValidationState(validationContext)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                        :name="$t('sst.components.bafCurrentAddNewItem.modal.labels.ht_amount')"
                        rules="required"
                        v-slot="validationContext"
                    >
                        <b-form-group
                            :label="$t('sst.components.bafCurrentAddNewItem.modal.labels.ht_amount')"
                            label-cols-sm="4"
                            label-cols-lg="3"
                        >
                            <b-input-group append="€">
                                <b-form-input
                                    id="ht_amount"
                                    name="ht_amount"
                                    disabled
                                    type="number"
                                    v-model="form.ht_amount"
                                    :state="getValidationState(validationContext)"
                                ></b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-form>
            </validation-observer>
            <template #modal-footer>
                <b-button :variant="'secondary'" @click="handleOk">
                    {{ $t('sst.components.bafCurrentAddNewItem.modal.buttons.save') }}
                </b-button>
                <b-button :variant="'danger'" @click="closeModal">
                    {{ $t('sst.components.bafCurrentAddNewItem.modal.buttons.cancel') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { BafRepository } from '@/repositories';
import eventBus from '@/plugins/eventBus';

export default {
    name: 'SstCurrentBafAddNewRow',
    props: {
        items: {
            required: true
        }
    },
    data() {
        return {
            form: {
                invoice_date: null,
                invoice_ref: null,
                ht_amount: _.sumBy(this.items, item => {
                    return _.floor(item.ht_amount, 2);
                })
            }
        };
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        resetModal() {},
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.onSubmit();
        },
        async onSubmit() {
            // Exit when the form isn't valid
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                return;
            }
            this.refreshData = true;
            BafRepository.createBaf(this.form)
                .then(() => {
                    this.refreshData = false;
                    this.$toast.success(this.$t('sst.components.bafCurrentAddNewItem.notifications.create.success'));
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-create');
                    });
                    eventBus.$emit('reloadSstBaf');
                })
                .catch(() => {
                    this.refreshData = false;
                    this.$toast.error(this.$t('sst.components.bafCurrentAddNewItem.notifications.create.error'));
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-create');
                    });
                });
        },
        closeModal() {
            this.$bvModal.hide('modal-create');
        }
    }
};
</script>

<style scoped></style>
