<template>
    <b-card>
        <b-card-title title-tag="h5">{{ $t('sst.pages.oneIntervention.documentTypes.' + documentType) }}</b-card-title>

        <b-card-text v-if="document.validation.validated === false && document.validation.cancellation_reason > 0">
            <p>{{ $t('sst.components.interventionDocumentSection.refusalSentence.' + documentType) }}</p>
            <p>
                {{
                    $t('sst.components.interventionDocumentSection.labels.reason', {
                        reason: $t('sst.pages.oneIntervention.cancelReason.' + document.validation.cancellation_reason)
                    })
                }}
            </p>
            <p v-if="document.validation.cancellation_memo">
                {{
                    $t('sst.components.interventionDocumentSection.labels.details', {
                        cancelationMemo: document.validation.cancellation_memo
                    })
                }}
            </p>
        </b-card-text>
        <b-card-text v-if="document.validation.validated === false && document.validation.cancellation_reason === null">
            {{ $t('sst.components.interventionDocumentSection.pendingSentence.' + documentType) }}
            <b-button size="sm" variant="link" @click.prevent="downloadFile()">
                <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
            </b-button>
        </b-card-text>
        <b-card-text v-if="document.validation.validated === true">
            {{ $t('sst.components.interventionDocumentSection.acceptedSentence.' + documentType) }}
            <b-button size="sm" variant="link" @click.prevent="downloadFile()">
                <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
            </b-button>
        </b-card-text>
    </b-card>
</template>

<script>
import { TABLE } from '@/constants';
import FileService from '@/services/FileService';

export default {
    name: 'SstInterventionDocumentSection',
    props: {
        documentType: {
            type: String,
            required: true
        },
        document: {
            type: Object,
            required: true
        },
        purchaseId: {
            type: Number,
            required: true
        },
        userIsGuest: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        downloadFile() {
            let downloadUrl = TABLE.PAGE.SST.INTERVENTION.DOWNLOAD_FILE_URL;
            if (this.userIsGuest) {
                downloadUrl = TABLE.PAGE.GUEST_SST.INTERVENTION.DOWNLOAD_FILE_URL;
            }
            const apiDownload = downloadUrl.replace('{documentId}', this.document.document_id).replace(
                '{purchaseId}',
                this.purchaseId
            );
            FileService.downloadFile(apiDownload, this.document.filename)
                .then(link => {
                    link.click();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('shared.components.customTable.notifications.errors.pdfNotFound'));
                });
        }
    }
};
</script>
