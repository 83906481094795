<template>
    <b-card class="row-details-bg">
        <b-row class="mb-2">
            <b-col>
                <span v-html="infoText()"></span>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import moment from 'moment';

export default {
    name: 'SstPendingInvoiceTableDetails',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    methods: {
        getBlockData() {
            return moment(this.row.item.block_date).format('DD/MM/YYYY');
        },
        infoText() {
            return this.$t('sst.components.pendingInvoiceTableDetails.info', {
                blockedDate: this.getBlockData(),
                memoBlock: this.row.item.block_memo
            });
        }
    }
};
</script>

<style scoped>
.row-details-bg {
    background: #f1b0b7;
}
</style>
